import { defineStore } from 'pinia'
import axios from 'axios'
import Cookies from 'js-cookie'

export const useAuthStore = defineStore('auth', {
	state: () => ({
		_token: Cookies.get('community_token'),
		_user_id: null,
		_fan_id: null,
		_user_fan_ids: [],
	}),
	getters: {
		userId: state => state._user_id,
		fanId: state => state._fan_id,
		token: state => state._token,
		userFanIds: state => Object.keys(state._user_fan_ids),
	},
	actions: {
		loginWithEmailPassword(payload) {
			const communitySlug = payload?.communitySlug
			return new Promise((resolve, reject) => {
				axios
					.post('v1/authentication/complex/login', payload, { baseURL: this.$authApiUrl })
					.then(response => {
						this._token = response.data.token
						const expires = new Date()
						expires.setDate(expires.getDate() + 30)
						Cookies.set('community_token', response.data.token, {
							expires,
							path: '/',
						})
						if (communitySlug) {
							// Verify access to given community
							this.checkCommunityAccess(communitySlug)
								.then(() => {
									this.getUserDetails(this._token)
										.then(() => {
											resolve()
										})
										.catch(error => {
											throw error
										})
								})
								.catch(error => {
									reject(error)
								})
						} else {
							resolve()
						}
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		loginWithToken(payload) {
			const communitySlug = payload.communitySlug
			return new Promise((resolve, reject) => {
				axios
					.post(
						'v1/authentication/complex/token_login',
						{},
						{
							baseURL: this.$authApiUrl,
							headers: {
								Authorization: `Bearer ${payload.token}`,
							},
						}
					)
					.then(response => {
						this._token = response.data.token
						const expires = new Date()
						expires.setDate(expires.getDate() + 30)
						Cookies.set('community_token', response.data.token, { expires, path: '/' })
						if (communitySlug) {
							this.checkCommunityAccess(communitySlug)
								.then(() => {
									this.getUserDetails(payload.token)
										.then(() => {
											resolve()
										})
										.catch(error => {
											throw error
										})
								})
								.catch(error => {
									reject(error)
								})
						} else {
							resolve()
						}
					})
					.catch(error => {
						console.error(error)
						reject()
					})
			})
		},
		getUserDetails(token) {
			return new Promise((resolve, reject) => {
				axios
					.get('v1/authentication/complex/get-ids', {
						baseURL: this.$authApiUrl,
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then(response => {
						this._user_id = response.data.user_id
						this._fan_id = response.data.fan_id
						this._user_fan_ids = response.data.user_fan_ids
						resolve()
					})
					.catch(error => {
						console.error(error)
						reject()
					})
			})
		},
		updatePassword(payload) {
			return new Promise((resolve, reject) => {
				axios
					.post('v1/authentication/fan/update-password', payload, {
						baseURL: this.$flaskApiUrl,
					})
					.then(() => {
						resolve()
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		logout(clear_root_token = false) {
			this._user_id = null
			this._token = null
			this._fan_id = null
			this._user_fan_ids = []
			Cookies.remove('community_token')
			if (clear_root_token) Cookies.remove('token')
		},
		checkCommunityAccess(communitySlug) {
			// If not a 401 we have access to this community
			return new Promise((resolve, reject) => {
				axios
					.get(`v1/communities/${communitySlug}/authentication`)
					.then(response => {
						resolve()
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},
})
